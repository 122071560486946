<template>
  <b-sidebar
    id="thanh-toan-them"
    :visible="isNapTienSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-nap-tien-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Mua gói đầu tư
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver2"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Số lượt xem -->
          <validation-provider
            #default="validationContext"
            name="Số bài"
          >
            <b-form-group
              label="Số bài"
              label-for="so-luot-xem-them"
            >
              <cleave
                id="so-luot-xem-them"
                v-model="giaoDichData.so_luot_xem"
                class="form-control"
                :raw="false"
                :state="getValidationState(validationContext)"
                :options="options.number"
                placeholder="10.000"
                @input="tinhThanhTien"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Ngày đầu tư -->
          <validation-provider
            #default="validationContext"
            name="Ngày đầu tư"
          >
            <b-form-group
              label="Ngày thực hiện"
              label-for="ngay-thanh-toan"
            >
              <flat-pickr
                id="ngay-thanh-toan"
                v-model="giaoDichData.ngay_thanh_toan"
                class="form-control"
                :config="configDate"
                placeholder="Chọn Ngày thực hiện"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <hr class="invoice-spacing">

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                icon="DollarSignIcon"
                class="mr-50"
              />
              Thanh toán
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Hủy
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isNapTienSidebarActive',
    event: 'update:is-nap-tien-sidebar-active',
  },
  props: {
    isNapTienSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      configDate: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
    }
  },
  setup(props, { emit }) {
    const currentUser = getUserData()
    const toast = useToast()

    const blankGiaoDichData = {
      giao_dich: null,
      so_tien_giao_dich: 0,
      so_tien: 0,
      don_gia: 0,
      ngay_thanh_toan: '',
      dai_ly: null,
      active: 1,
      ma_giao_dich: null,
      khach_hang: '',
      so_luot_xem: 0,
      loai_giao_dich: 'Mua bài',
      khuyen_mai: 0,
      auth: currentUser.auth_key,
      uid: currentUser.id,
    }

    const giaoDichData = ref(JSON.parse(JSON.stringify(blankGiaoDichData)))
    const resetuserData = () => {
      giaoDichData.value = JSON.parse(JSON.stringify(blankGiaoDichData))
    }
    const setGiaoDichData = user => {
      giaoDichData.value = user
    }

    const tinhThanhTien = () => {
      let soLuotXem = 0
      if ((giaoDichData.value.so_luot_xem !== 0)) soLuotXem = Number(giaoDichData.value.so_luot_xem.replaceAll(',', ''))
      let donGia = 0
      if ((giaoDichData.value.don_gia !== 0)) donGia = Number(giaoDichData.value.don_gia.replaceAll(',', ''))
      let khuyenMai = 0
      if ((giaoDichData.value.khuyen_mai) !== 0) khuyenMai = Number(giaoDichData.value.khuyen_mai.replaceAll(',', ''))
      giaoDichData.value.so_tien_giao_dich = soLuotXem * donGia - khuyenMai
    }

    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }
    const onSubmit = () => {
      store.state.showBlock = true
      store.dispatch('app-user/muaBai', giaoDichData.value)
        .then(response => {
          showToast('success', 'Thông báo', 'BellIcon', response.data.content)
          emit('refetch-data')
          emit('update:is-nap-tien-sidebar-active', false)
          store.state.showBlock = false
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    }
    const nhanMaGiaoDich = () => {
      if (giaoDichData.value.so_luot_xem === 0) showToast('danger', 'Thông báo', 'BellIcon', 'Chưa nhập số bài')
      else if (giaoDichData.value.ngay_thanh_toan === '') showToast('danger', 'Thông báo', 'BellIcon', 'Chưa nhập ngày thực hiện')
      else {
        store.state.showBlock = true
        store.dispatch('app-user/nhanMaGiaoDich', giaoDichData.value)
          .then(response => {
            const oldGiaoDichData = giaoDichData.value
            Object.assign(oldGiaoDichData, {
              giao_dich: response.data.sendmagiaodich,
            })
            setGiaoDichData(oldGiaoDichData)
            showToast('success', 'Thông báo', 'BellIcon', response.data.content)
            store.state.showBlock = false
          })
          .catch(e => {
            if (typeof e.response !== 'undefined') {
              showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
            } else if (typeof e.message !== 'undefined') {
              showToast('danger', 'Thông báo', 'BellIcon', e.message)
            } else {
              showToast('danger', 'Thông báo', 'BellIcon', e)
            }
            store.state.showBlock = false
          })
      }
    }
    const {
      refFormObserver2,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      currentUser,
      giaoDichData,
      nhanMaGiaoDich,
      onSubmit,
      refFormObserver2,
      getValidationState,
      resetForm,
      tinhThanhTien,
      setGiaoDichData,
    }
  },
  mounted() {
    this.$root.$on('napTien', daiLy => {
      const newGiaoDich = { dai_ly: daiLy }
      Object.assign(newGiaoDich, {
        auth: this.currentUser.auth_key,
        uid: this.currentUser.id,
      })
      const oldGiaoDichData = this.giaoDichData
      Object.assign(oldGiaoDichData, newGiaoDich)
      this.setGiaoDichData(oldGiaoDichData)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
