import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// import axios from 'axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default function useKhachHangList() {
  // Use toast
  const toast = useToast()
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'STT', thStyle: { width: '3%' } },
    {
      key: 'ma_dai_ly', sortable: true, label: 'Mã đại lý', thStyle: { width: '3%' },
    },
    {
      key: 'hoten', sortable: true, label: 'Họ tên', thStyle: { width: '30%' },
    },
    {
      key: 'dien_thoai', sortable: true, label: 'Điện thoại', thStyle: { width: '10%' },
    },
    {
      key: 'so_luong_khach', sortable: true, label: 'Số bài', thStyle: { width: '10%' },
    },
    {
      key: 'so_luong_dai_ly', sortable: true, label: 'Đ/Lý', thStyle: { width: '10%' },
    },
    {
      key: 'so_lan_xem_con_lai', sortable: true, label: 'Còn lại', thStyle: { width: '10%' },
    },
    { key: 'actions', label: 'Tác vụ', thStyle: { width: '7%' } },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const fieldsTimKiem = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const currentUser = getUserData()

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })
  const fetchDaiLyList = (ctx, callback) => {
    const userData = getUserData()

    store
      .dispatch('app-user/fetchAllDaiLyList', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        offset: currentPage.value,
        limit: perPage.value,
        auth: userData.auth_key,
        uid: userData.id,
        fieldsSearch: fieldsTimKiem,
      })
      .then(response => {
        const { users, rows } = response.data

        callback(users)
        totalUsers.value = rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = user => {
    if (user.con_no <= 0) return 'success'
    return 'danger'
  }

  const xoaKhachHang = (id, callback, before, after, showToast) => {
    before()
    store
      .dispatch('app-user/deleteKhachHang', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        khach_hang: id,
      })
      .then(response => {
        callback()
        after()
        showToast(response.data.message, 'success')
      })
      .catch(e => {
        after()
        showToast(e.message, 'danger')
      })
  }

  return {
    fieldsTimKiem,
    fetchDaiLyList,
    xoaKhachHang,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
